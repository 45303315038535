<script>
import { componentExists } from '@/site/autoloader'
export default {
	props: {
		order: Object,
	},
	computed: {
		showCard() {
			return componentExists(this.componentName)
		},
		componentName() {
			return `${this.order.payments[0].methodKey}-Payment-InstructionsCardContent`
		},
	},
}
</script>

<template>
	<CardLayout
		title="¿Cómo realizo el pago?"
		v-bind="$attrs"
		v-on="$listeners"
		v-if="order.payments.length && showCard"
	>
		<component :is="componentName" :order="order" />
	</CardLayout>
</template>
